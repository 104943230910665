.thanks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 30px;
  box-sizing: border-box;
  flex-grow: 1;
}

.thanks_title {
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  text-align: center;
  color: #000000;
  margin-bottom: 14px;
  margin-top: 30px;
}

.thanks_subtitle {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 160%;
  text-align: center;
  color: #121111;
  margin-bottom: 50px;
}

.thanks_button {
  min-width: 260px;
  min-height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #FFFFFF;
}

@media(max-width: 1190px) {
  .thanks_title {
    font-size: 54px;
    line-height: 110%;
    margin-bottom: 14px;
  }

  .thanks_subtitle {
    font-size: 18px;
    line-height: 160%;
  }
}

@media(max-width: 767px) {
  .thanks_title {
    font-size: 38px;
    line-height: 110%;
    margin-top: 20px;
  }

  .thanks_subtitle {
    margin-bottom: 40px;
  }
}
