.consultation-popup {
  position: absolute;
  background: rgba(43, 43, 43, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  transform: scale(0);
  position: fixed;
  display: flex;

  &.open {
    transform: scale(1);
  }
}

.consultation-popup-form {
  background: white;
  box-shadow: 0px 10px 34px #193C66;
  border-radius: 12px;
  top: 156px;
  width: 600px;
  flex-shrink: 0;
  padding: 75px 95px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  transform: scale(0);
  transition: all 0.5s ease-in-out;
}

.consultation-popup.open .consultation-popup-form {
  transform: scale(1);
  transition: all 0.5s ease-in-out ;
}

.consultation-popup-form .custom-select {
  margin-right: 30px;
  margin-left: 30px;
}

.consultation-popup-form_title {
  font-family: 'Roboto', sans-serif;
  font-size: 42px;
  line-height: 110%;
  color: #000000;
  margin-top: 0;
  margin-bottom: 30px;
}

.consultation-popup-form_button-close {
  position: absolute;
  top: 30px;
  right: 30px;

  & svg {
    height: 24px;
    width: 24px;
  }

  &:hover {
    cursor: pointer;

    & svg {
      stroke: rgb(56, 104, 162);
    }
  }
}

.consultation-popup-form_input {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: black;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding: 16px 15px;
  margin-bottom: 12px;
  margin-right: 30px;
  margin-left: 30px;

  &::placeholder {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #A6A6A6;
  }

  &:hover {
    border-color: #999999;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    outline-offset: 0;
  }
}

.consultation-popup-form_submit {
  padding: 22px 77px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  margin-top: 17px;
  align-self: center;
}

@media(max-width: 1190px) {
  .consultation-popup-form {
    top: 228px;
  }
}

@media(max-width: 767px) {
  .consultation-popup-form {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    top: 0;
    border-radius: 0;
    padding: 61px 30px;
    box-sizing: border-box;
  }

  .consultation-popup-form_title {
    font-size: 24px;
    line-height: 110%;
    text-align: center;
  }

  .consultation-popup-form_button-close {
    top: 15px;
    right: 15px;

    & svg {
    height: 20px;
    width: 20px;
    }
  }

  .consultation-popup-form .custom-select,
  .consultation-popup-form input {
    margin-right: 0;
    margin-left: 0;
  }
}
