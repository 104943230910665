.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 30px;
  box-sizing: border-box;
  flex-grow: 1;
}

.error_button {
  padding-top: 22px;
  padding-bottom: 22px;
  min-width: 260px;
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #FFFFFF;
}

@media(max-width: 767px) {
  .error {
    padding-right: 0;
    padding-left: 0;
  }

  .error_img {
    width: 100%
  }

  .error_button {
    margin-top: 40px;
  }
}
