.company {
  background: #F3F3F3;
  padding-top: 180px;
  padding-bottom: 180px;
}

.company_about {
  display: flex;
  flex-direction: row;
}

.company_director {
  position: relative;
  margin-right: 130px;
}

.company_director-img {
  border-radius: 50%;
}

.company_director-text {
  position: absolute;
  bottom: 118px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  text-align: center;
  color: #FFFFFF;
  width: 248px;
  left: calc(50% - 124px);

  & p {
    margin: 0;
  }
}

.company_title {
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  color: #121111;
  margin-top: 196px;
  margin-bottom: 50px;
}

.company_description {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 160%;
  color: #121111;
  max-width: 470px;
  margin: 0;
}

.company_lidogeneration-plus {
  margin-top: 81px;
  display: flex;
  flex-direction: row;

  & div {
    margin-top: 17px;
  }
}

.lidogeneration-plus_title {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  line-height: 110%;
  color: #121111;
  flex-shrink: 0;
  padding-left: 100px;
  margin-right: 100px;
  margin-top: 0;
  margin-bottom: 0;
}

.lidogeneration-plus_description {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 140%;
  color: #121111;
  position: relative;
  padding-left: 25px;
  max-width: 443px;
  margin-top: 0;
  margin-bottom: 18px;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
    width: 15px;
    height: 15px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='6' width='3' height='15' fill='%234BB1E9'/%3E%3Crect x='15' y='6' width='3' height='15' transform='rotate(90 15 6)' fill='%234BB1E9'/%3E%3C/svg%3E%0A");
  }
}

.lidogeneration-plus_total {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 160%;
  color: #121111;
  max-width: 440px;
  margin-top: 51px;
}

.company_partners {
  margin-top: 180px;
  display: flex;
  flex-direction: row;
}

.partners_title {
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  color: #121111;
  margin-bottom: 50px;
  margin-top: 0;
}

.partners_descrition {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 160%;
  color: #000000;
  max-width: 470px;
  margin: 0;
}

.partners_logos {
  flex-basis: 500px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 100px;
}

.partners_logo {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  margin-right: 30px;
  margin-bottom: 30px;

  & img {
    width: 100%;
    height: auto;
  }
}

@media(max-width: 1190px) {
  .company_director {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .company_director-img {
    width: 330px;
    height: 330px;
  }

  .company_director-text {
    color: #2B2B2B;
    position: static;
    max-width: 270px;
    margin-top: 13px;

    & br.none-in-table {
      display: none;
    }
  }

  .company_title {
    font-size: 54px;
    line-height: 110%;
    margin-top: 135px;
  }

  .company_description {
    font-size: 18px;
    line-height: 160%;
  }

  .company_lidogeneration-plus {
    flex-direction: column;
    margin-top: 100px;

    & div {
      margin-top: 0;
    }
  }

  .lidogeneration-plus_title {
    padding-left: 0;
    margin-right: 0;
    margin-bottom: 50px;
  }

  .company_partners {
    flex-direction: column;
  }

  .partners_title {
    font-size: 54px;
    line-height: 110%;
    margin-bottom: 30px;

    & span {
      display: none;
    }
  }

  .partners_logo {
    width: 150px;
    height: 150px;
    margin-bottom: 0;
  }

  .partners_logos {
    order: 2;
    flex-basis: auto;
    padding-left: 0;
    padding-top: 50px;
  }

  .partners_text {
    order: 1;
  }
}

@media(max-width: 767px) {
  .company {
    padding-top: 100px;
    padding-bottom: 140px;
  }

  .company_director-img {
    width: 290px;
    height: 290px;
  }

  .company_director {
    order: 2;
  }

  .company_text {
    order: 1;
  }

  .company_title {
    font-size: 38px;
    line-height: 110%;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .company_description {
    font-size: 17px;
    line-height: 150%;
    margin-bottom: 30px;
  }

  .company_director {
    margin-right: 0;
  }

  .company_director-text {
    font-size: 14px;
    line-height: 140%;
    margin-top: 23px;
    width: 288px;
    max-width: none;

    & br {
      display: none;
    }
  }

  .company_about {
    flex-direction: column;
  }

  .lidogeneration-plus_title {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 110%;
  }

  .lidogeneration-plus_total {
    margin-top: 30px;
  }

  .company_partners {
    margin-top: 140px;
  }

  .partners_title {
    font-size: 38px;
    line-height: 110%;
  }

  .partners_descrition {
    font-size: 17px;
    line-height: 150%;
  }

  .company_partners {
    overflow: hidden;
  }

  .partners_logos {
    margin-right: 0;
    margin-left: 0;
    justify-content: space-between;
  }

  .partners_logo {
    flex: 0 1 calc(50% - 20px);
    height: auto;
    margin-left: 0;
    margin-bottom: 20px;
    margin-right: 0;

    &:nth-of-type(odd) {
      margin-right: 20px;
    }
  }

  .company_lidogeneration-plus {
    margin-top: 80px;
  }

  .lidogeneration-plus_total {
    margin-bottom: 0;
  }
}
