.footer-contacts {
  padding-top: 150px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-contacts_title {
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  color: #121111;
  flex-shrink: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.footer-contacts_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-basis: 570px;
  padding-top: 20px;
}

.footer-contacts_item {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  color: #B3B7C1;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 34px;

  & a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    color: #121111;
    text-decoration: none;
    margin-top: 10px;

    &[href]:hover {
      color: #6ADF91;
    }
  }
}

.footer-contacts_map {
  height: 450px;
  width: 100%;
  margin-top: 6px;
  background: url("../img/map-decktop_1x.jpg") no-repeat;
  background-size: cover;
}


@media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
  .footer-contacts_map {
    background: url("../img/map-decktop_2x.jpg") no-repeat;
    background-size: cover;
  }
}

@media(max-width: 1190px) {
  .footer-contacts_map {
    background: url("../img/map-table_1x.jpg") no-repeat;
    background-size: cover;
  }
}

@media(max-width: 1190px) and (min-resolution: 1.5dppx) {
  .footer-contacts_map {
    background: url("../img/map-table_2x.jpg") no-repeat;
    background-size: cover;
  }
}

@media(max-width: 1190px) {
  .footer-contacts_title {
    font-size: 54px;
    line-height: 110%;
  }

  .footer-contacts_list {
    padding-top: 50px;
  }
}

@media(max-width: 767px) {
  .footer-contacts {
    padding-top: 100px;
    padding-bottom: 80px;
    margin-top: 20px;
  }

  .footer-contacts_title {
    font-size: 38px;
    line-height: 110%;
  }

  .footer-contacts_list {
    padding-top: 30px;
    flex-direction: column;
  }

  .footer-contacts_item {
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 22px;

    & a {
      font-size: 19px;
      line-height: 140%;
      margin-top: 9px;
    }
  }

  .footer-contacts_map {
    margin-top: 20px;
  }
}

@media(max-width: 320px) {
  .footer-contacts_map {
    background: url("../img/map-mob_1x.jpg") no-repeat;
    background-size: cover;
  }
}


@media(max-width: 320px) and (min-resolution: 1.5dppx) {
  .footer-contacts_map {
    background: url("../img/map-mob_2x.jpg") no-repeat;
    background-size: cover;
  }
}
