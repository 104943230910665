html,
body {
  min-height: 100vh
}

html {
  scroll-behavior: smooth;
}

body {
  background: white;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #121111;
  min-width: 320px;
  position: relative;
  font-display: swap;
  display: flex;
  flex-direction: column;
}

p, h1 {
  margin: 0;
}

.main {
  flex-grow: 1;
  padding-top: 87px;
  display: flex;
  flex-direction: column;
}

@media(max-width: 767px) {
  .main {
    padding-top: 65px;
  }
}

.center {
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
}

@media(max-width: 1190px) {
  .center {
    max-width: 690px;
  }
}

@media(max-width: 767px) {
  .center {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.button {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  color: #FFFFFF;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  &--transparent {
    background: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 30px;

    &:hover {
      background: #6ADF91;
      border-color: #6ADF91;
    }
  }

  &--colored {
    background: #6ADF91;
    box-shadow: 0px 10px 34px rgba(106, 223, 145, 0.5);
    border-radius: 35px;
    border: none;

    &:hover {
      background: #62D187;
    }
  }

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    outline-offset: 0;
  }
}

input.isValidInput,
input.telmask {
  background-position: 96% 50%;
  background-repeat: no-repeat;

  &.valid {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.646 14.818a.5.5 0 00.707 0l8.132-8.132A1 1 0 0119.9 8.1l-9.193 9.193a1 1 0 01-1.414 0l-4.95-4.95a1 1 0 111.414-1.414l3.89 3.89z' fill='%238BBF56'/%3E%3C/svg%3E");
  }

  &.invalid {
    border-color: #dc5858;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 7L7 17m10 0L7 7' stroke='%23DC5858' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  }
}

button:disabled {
  pointer-events: none;
  cursor: no-drop;
  opacity: .65;
}
