.custom-select {
  position: relative;
  margin-bottom: 12px;
}

.custom-select_selected-option-wrapper {
  position: relative;

  & svg {
    position: absolute;
    right: 15px;
    top: 13px;
    transition: transform 0.3s ease-in-out;
  }

  &:hover svg {
    fill: #6ADF91;
  }
}

.custom-select.open .custom-select_selected-option-wrapper svg {
  transform: rotate(-180deg);
}

.custom-select_selected-option {
  width: 100%;
  padding: 16px 15px;
  box-sizing: border-box;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #121111;
  text-align: left;
  z-index: 1;
  position: relative;
  background: transparent;

  &:hover {
    cursor: pointer;
    border-color: #999999;
  }

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    outline-offset: 0;
  }
}

.custom-select_options {
  padding-left: 0;
  list-style: none;
  padding: 15px 15px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
  margin: 0;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 0;
  transition: height 1s ease-out;
  transform: scaleY(0);
}

.custom-select.open .custom-select_options {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.4s ease;
  height: auto;
}

.custom-select_option {
  text-align: left;
  margin-top: 0;
  margin-bottom: 10px;

  &:hover {
    color: #6ADF91;
    cursor: pointer;
  }
}
