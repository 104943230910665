.poster {
  background: url('../img/poster_1x.jpg') center;
  padding-top: 150px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 186px;
  text-align: center;
  background-size: cover;

  &--bigger {
    background: url('../img/poster2_1x.jpg') center;
    background-size: cover;
  }
}

@media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
  .poster {
    background: url('../img/poster_2x.jpg') center;
    background-size: cover;

    &--bigger {
      background: url('../img/poster2_2x.jpg') center;
      background-size: cover;
    }
  }
}

.poster_title {
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  color: #FFFFFF;
  margin-bottom: 50px;
  margin-top: 0;
}

.poster_button {
  padding: 22px 32px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #FFFFFF;
}

.poster-form {
  box-shadow: 0px 10px 34px rgba(56, 104, 162, 0.5);
  padding: 50px 60px;
  min-width: 470px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.poster-form_input {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: black;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding: 16px 15px;
  margin-bottom: 12px;

  &::placeholder {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #A6A6A6;
  }

  &:hover {
    border-color: #999999;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    outline-offset: 0;
  }
}

.poster-form_submit {
  padding: 22px 77px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  margin-top: 17px;
  align-self: center;
}

@media(max-width: 1190px) {
  .poster {
    padding-top: 170px;
    padding-bottom: 170px;
    background: url('../img/poster-tablet_1x.jpg') center no-repeat;
    background-size: cover;
    margin-top: 180px;

    &--bigger {
      background: url('../img/poster-tablet2_1x.jpg') center no-repeat;
      background-size: cover;
    }
  }

  .poster_title {
    font-size: 54px;
    line-height: 110%;
  }
}

@media (min-resolution: 144dpi) and (max-width: 1190px) {
  .poster {
    background: url('../img/poster-tablet_2x.jpg') center no-repeat;
    background-size: cover;

    &--bigger {
      background: url('../img/poster-tablet2_2x.jpg') center no-repeat;
      background-size: cover;
    }
  }
}

@media(max-width: 767px) {
  .poster {
    padding: 120px 15px 140px 15px;
    background: url('../img/poster-mob_1x.jpg') center no-repeat;
    background-size: cover;
    margin-top: 140px;

    &--bigger {
      padding: 100px 15px;
      background: url('../img/poster-mob2_1x.jpg') center no-repeat;
      background-size: cover;
    }
  }

  .poster_title {
    font-size: 38px;
    line-height: 110%;
    margin-bottom: 50px;
  }

  .poster--bigger .poster_title {
    margin-bottom: 40px;
  }

  .poster_form {
    padding: 30px 15px;
    min-width: 100%;
  }
}

@media (min-resolution: 144dpi) and (max-width: 767px) {
  .poster {
    background: url('../img/poster-mob_2x.jpg') center no-repeat;
    background-size: cover;

    &--bigger {
      background: url('../img/poster-mob2_2x.jpg') center no-repeat;
      background-size: cover;
    }
  }
}

