.profitability {
  background: #6ADF91;
  margin-top: 134px;

  & .center {
    padding: 180px 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    box-sizing: border-box;
  }
}

.profitability_title {
  display: inline-block;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 50px;
}

.profitability_form {
  background: #FFFFFF;
  box-shadow: 0px 10px 34px rgba(64, 132, 87, 0.5);
  border-radius: 12px;
  padding: 40px;
  flex-basis: 655px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.profitability_item {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
}

.profitability_input {
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: black;
  padding: 14px 20px;
  min-width: 341px;
  box-sizing: border-box;

  &::placeholder {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #A6A6A6;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: #999999;
    outline: 0;
    outline-offset: 0;
  }
}

.profitability_button {
  margin-top: 16px;
  padding: 22px 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
}

.profitability_calculation {
  background: #4BB1E9;
  box-shadow: 0px 10px 34px rgba(64, 132, 87, 0.5);
  border-radius: 12px;
  padding: 40px 40px 13px 40px;
  width: 285px;
  box-sizing: border-box;
}

.profitability_total {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 27px;
  display: flex;
  flex-direction: column;

  & span {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    line-height: 110%;
    color: #FFFFFF;
    margin-top: 8px;
    word-wrap: break-word;
  }
}

@media(max-width: 1190px) {
  .profitability .center {
    padding-right: 0;
    padding-left: 0;
  }

  .profitability_title {
    font-size: 54px;
    line-height: 110%;
  }

  .profitability_form {
    flex-basis: 390px;
    margin-right: 15px;
  }

  .profitability_item {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .profitability_input {
    min-width: auto;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 30px;
  }

  .profitability_button {
    margin-top: 10px;
  }
}

@media(max-width: 767px) {
  .profitability {
    margin-top: 100px;

    & .center {
      padding: 100px 15px;
    }
  }

  .profitability_title {
    font-size: 38px;
    line-height: 110%;
    margin-bottom: 40px;
  }

  .profitability_form {
    padding: 30px 15px;
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .profitability_input {
    margin-bottom: 23px;
  }

  .profitability_button {
    margin-top: 5px;
  }

  .profitability_total {
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 22px;

    & span {
      font-size: 24px;
      line-height: 100%;
    }
  }

  .profitability_calculation {
    padding: 30px 20px 10px 20px;
    width: 100%;
    box-sizing: border-box;
  }
}
