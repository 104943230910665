.blog {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 100px;
  box-sizing: border-box;
}

.blog h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  color: #121111;
  margin-bottom: 80px;
  width: 100%;
  font-weight: normal;
}

.in-article.blog h1 {
  margin-bottom: 50px;
}

.blog_item {
  flex-basis: 48%;
  flex-shrink: 0;
  text-decoration: none;
  margin-bottom: 80px;

  &:hover {
    cursor: pointer;

    & h1 {
      color: #6ADF91;
    }
  }
}

.blog_img {
  height: 338px;
  width: 100%;
  background: #F3F3F3;
  border-radius: 12px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

.blog_subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  line-height: 110%;
  color: #121111;
  font-weight: normal;
}

@media(max-width: 1190px) {
  .blog {
    flex-direction: column;
    padding-bottom: 80px;
  }

  .blog h1 {
    font-size: 54px;
    line-height: 110%;
  }

  .in-article.blog h1 {
    margin-bottom: 80px;
  }

  .blog_item {
    width: 100%;
    margin-bottom: 60px;
  }
}

@media(max-width: 767px) {
  .blog {
    flex-direction: column;
    padding-bottom: 60px;
  }

 .blog h1 {
  font-size: 38px;
  line-height: 110%;
 }

 .blog_item {
   margin-bottom: 40px;
 }

 .blog_img  {
    margin-bottom: 15px;
  }

  .blog_subtitle {
    font-size: 20px;
  }
}
