.advantages {
  margin-top: 120px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 100px;
  padding-left: 100px;
  box-sizing: border-box;
}

.advantages_title {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  color: #121111;
  margin-bottom: 80px;
}

.advantage {
  flex-basis: 460px;
  flex-shrink: 0;
  position: relative;
  padding-left: 142px;
  box-sizing: border-box;
  margin-bottom: 35px;

  &--small-text {
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;

    & .advantage_description {
      font-family: 'Roboto', sans-serif;
      font-size: 24px;
      line-height: 130%;
      color: #121111;
      margin: 0;
    }
  }
}

.advantage_img {
  position: absolute;
  top: 0;
  left: 0;
}

.advantage_title {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  line-height: 110%;
  color: #121111;
  margin-top: 23px;
  margin-bottom: 20px;
}

.advantage_description {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 150%;
  color: #121111;
}

@media(max-width: 1190px) {
  .advantages {
    padding-right: 0;
    padding-left: 0;
  }

  .advantages_title {
    font-size: 54px;
    line-height: 110%;
    margin-bottom: 90px;
  }

  .advantage {
    flex-basis: 330px;
    padding-left: 115px;

    &--small-text {
      min-height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 46px;

      & .advantage_description {
        font-size: 21px;
        line-height: 110%;
      }
    }
  }

  .advantage_title {
    font-size: 23px;
    line-height: 110%;
  }

  .advantage_description {
    font-size: 19px;
    line-height: 150%;
  }

  .advantage_img {
    width: 100px;
    height: 100px;
  }
}

@media(max-width: 767px) {
  .advantages {
    margin-top: 80px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .advantages_title {
    font-size: 38px;
    line-height: 110%;
    margin-bottom: 47px;
    margin-top: 65px;
  }

  .advantage {
    flex-basis: 100%;
    padding-left: 95px;
    margin-bottom: 40px;
  }

  .advantage_img {
    width: 80px;
    height: 80px;
  }

  .advantage_title {
    font-size: 20px;
    line-height: 110%;
    margin-top: 17px;
    margin-bottom: 12px;
  }

  .advantage_description {
    font-size: 17px;
    line-height: 130%;
    margin: 0;
  }

  .advantage--small-text {
    margin-bottom: 40px;
    min-height: 80px;

    & .advantage_description {
      font-size: 19px;
    }
  }
}
