.article {
  padding: 153px 100px 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.article h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  text-align: center;
  color: #121111;
  margin-bottom: 80px;
  font-weight: normal;
}

.article_img {
  background: #F3F3F3;
  border-radius: 12px;
  max-width: 968px;
  width: 100%;
  height: 546px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

.article_text-wrapper {
  padding: 0 98px;
}

.article_text-wrapper h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  line-height: 120%;
  color: #121111;
  margin-top: 45px;
  margin-bottom: 20px;
  font-weight: normal;
}

.article_text-wrapper h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  line-height: 120%;
  color: #121111;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: normal;
}

.article_text-wrapper p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 170%;
  color: #121111;
  margin-bottom: 15px;
}

.article_text-wrapper ul p {
  font-family: 'Roboto', sans-serif;
  font-size: 21px;
  line-height: 110%;
  color: #121111;
  margin-bottom: 15px;
  margin-top: 30px;
}

.article_text-wrapper ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 30px;
}

.article_text-wrapper li {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 140%;
  color: #121111;
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 8px);
    left: 0;
    background: url("data:image/svg+xml,%0A%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='6' width='3' height='15' fill='%234BB1E9'/%3E%3Crect x='15' y='6' width='3' height='15' transform='rotate(90 15 6)' fill='%234BB1E9'/%3E%3C/svg%3E%0A") center no-repeat;
    width: 16px;
    height: 16px;
  }
}

@media(max-width: 1190px) {
  .article {
    padding: 153px 0 200px;
  }

  .article h1 {
    font-size: 54px;
    margin-bottom: 60px;
  }

  .article_img {
    height: 400px;
  }

  .article_text-wrapper {
    padding: 0;
  }
}

@media(max-width: 767px) {
  .article {
    padding: 75px 15px 140px;
  }

  .article h1 {
    font-size: 38px;
    margin-bottom: 40px;
  }

  .article_img {
    height: 180px;
    margin-bottom: 30px;
  }

  .article_text-wrapper h1,
  .article_text-wrapper h2 {
    font-size: 28px;
    margin-bottom: 15px;
    margin-top: 50px;
  }

  .article_text-wrapper ul {
    margin-bottom: 20px;
  }

}
