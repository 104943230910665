.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header--main {
  background: radial-gradient(59.53% 115.19% at 65.96% 69.26%,#4bb1e9 0,#3883e9 52.36%,#0455c1 91%,#004fb9 100%);
  height: 100vh;
  min-height: 740px;
  overflow: hidden;

  & .header_navbar {
    background: none;
  }
}

.header_navbar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  background: radial-gradient(59.53% 115.19% at 65.96% 69.26%, #4BB1E9 0%, #3883E9 52.36%, #0455C1 91%, #004FB9 100%);
  padding-top: 22px;
  padding-bottom: 22px;

  & .center {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &.scroll {
    position: fixed;
    background: radial-gradient(59.53% 115.19% at 65.96% 69.26%, #4BB1E9 0%, #3883E9 52.36%, #0455C1 91%, #004FB9 100%);
  }
}

.navbar_logo {
  margin-right: 251px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.contacts {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contacts_item {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #FFFFFF;
  transition: all 0.7s ease-in-out;
  flex-shrink: 0;

  & svg {
    transition: all 0.7s ease-in-out;
  }

  &--whatsapp,
  &--viber {
    margin-right: 4px;
  }

  &--telegram {
    margin-right: 10px;
  }

  &--phone {
    margin-right: 40px;
  }

  &--email {
    margin-right: 70px;

    & svg {
      margin-right: 10px;
    }
  }

  &:hover {
    cursor: pointer;
    color: #6ADF91;

    & svg {
      fill: #6ADF91;
    }
  }
}

.navbar_button {
  margin-right: 70px;
  padding: 10px 0;
  min-width: 175px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  color: #FFFFFF;
}

.navbar_burger {
  position: relative;
  width: 28px;
  height: 15px;
  z-index: 999;

  &:hover {
    cursor: pointer;
  }

  & span {
    display: block;
    position: absolute;
    width: 14px;
    height: 2px;
    background: white;
    transition: 0.25s ease-in-out;

    &:nth-child(even) {
      left: 50%;
    }

    &:nth-child(odd) {
      left:0px;
    }

    &:nth-child(1),
    &:nth-child(2) {
      top: 0px;
    }

    &:nth-child(3),
    &:nth-child(4) {
      top: 6px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      top: 12px;
    }
  }
}

.navbar_burger.open span {
  width: 20px;

  &:nth-child(1),
  &:nth-child(6) {
    transform: rotate(45deg);
  }

  &:nth-child(2),
  &:nth-child(5) {
    transform: rotate(-45deg);
  }

  &:nth-child(1) {
    left: 7px;
    top: 7px;
  }

  &:nth-child(2) {
    left: calc(50% - 7px);
    top: 7px;
  }

  &:nth-child(3) {
      left: -50%;
      opacity: 0;
  }

  &:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  &:nth-child(5) {
    left: 7px;
    top: 7px;
  }

  &:nth-child(6) {
    left: calc(50% - 7px);
    top: 7px;
  }
}

.menu {
  background:  rgba(43, 43, 43, 0.5);
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  transform: translateX(100%);
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &.open {
    transform: translateX(0);
  }
}

.menu_list {
  background: #010E30;
  width: 845px;
  padding-top: 185px;
  padding-left: 70px;
  box-sizing: border-box;
  height: 100%;
  transform: translateX(100%);
  transition: all 0.8s ease-in-out;
  overflow-y: scroll;
}

.menu.open .menu_list {
  transform: translateX(0);
  transition: all 0.8s ease-in-out;
}

.menu_link {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  line-height: 110%;
  color: #FFFFFF;
  text-decoration: none;
  margin-bottom: 38px;

  &:hover {
    color: #6ADF91;
  }
}

@media(max-width: 1190px) {
  .menu_list {
    width: 100vw;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding-left: 0;
    padding-top: 218px;
  }

  .menu_link {
    text-align: center;
    margin-bottom: 40px;
  }

  .navbar_logo {
    z-index: 999;
  }
}

@media(max-width: 767px) {
  .menu_list {
    padding-top: 100px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .menu_link {
    text-align: left;
    margin-bottom: 22px;
    font-size: 20px;
    line-height: 110%;
  }
}

.banner {
  padding-bottom: 258px;
  padding-top: 182px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & div {
    flex-shrink: 0;
  }
}

.banner_title {
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  color: #FFFFFF;
  margin-bottom: 40px;
}

.banner_description {
  display: block;
  max-width: 470px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 170%;
  color: #FFFFFF;
  margin-bottom: 66px;
}

.banner_button {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #FFFFFF;
  padding: 22px 32px;
}


@media(max-width: 1190px) {
  .header {
    position: relative;
  }

  .navbar_logo {
    margin-right: 189px;
  }

  .contacts_item--email,
  .navbar_button {
    display: none;
  }

  .contacts_item--phone {
    margin-right: 110px;
  }

  .banner {
    padding-top: 205px;
    padding-bottom: 262px;
    position: static;
  }

  .banner_title {
    font-size: 54px;
    line-height: 110%;
  }

  .banner_description {
    max-width: 330px;
  }

}

@media(max-width: 767px) {
  .header--main {
    min-height: 789px;
    height: auto;
  }

  .navbar_contacts {
    display: none;
  }

  .header_navbar {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .navbar .center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .navbar_logo {
    width: 126.41px;
    height: 35px;
    margin-right: 0;
  }

  .banner {
    padding-top: calc(50px + 65px);
    padding-bottom: 20px;
    flex-direction: column;
    width: auto;

    & div,
    & picture {
      width: 100%;
    }
  }

  .banner_title {
    font-size: 42px;
    line-height: 110%;
    margin-bottom: 30px;
  }

  .banner_description {
    font-size: 17px;
    line-height: 150%;
    margin-bottom: 30px;
  }

  .banner_button {
    margin-bottom: 30px;
    width: 100%;
  }

  .banner_img {
    width: calc(100% + 30px);
    height: auto;
    margin-right: -15px;
    margin-left: -15px;
  }
}
