.clients {
  margin-top: 148px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.clients_title {
  font-family: 'Gramatika-Regular', sans-serif;
  font-size: 72px;
  line-height: 110%;
  color: #121111;
  flex-shrink: 0;
  margin-right: 97px;
  margin-top: 0;
  margin-bottom: 0;
}

.clients_subtitle {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 160%;
  color: #121111;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 470px;
  padding-top: 26px;
}

.clients_logos {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 80px;
  box-sizing: border-box;
}

.client_logo {
  margin-bottom: 30px;
  width: 270px;
  height: 270px;

  & img {
    width: 100%;
    height: auto;
  }

  &:not(:nth-of-type(4n+4)) {
    margin-right: 30px;
  }
}

@media(max-width: 1190px) {
  .clients {
    margin-top: 180px;
  }

  .clients_title {
    font-size: 54px;
    line-height: 110%;
  }

  .clients_subtitle {
    font-size: 18px;
    line-height: 160%;
  }

  .client_logo {
    width: 210px;
    height: 210px;

    &:not(:nth-of-type(4n+4)) {
      margin-right: 0;
    }

    &:not(:nth-of-type(3n+3)) {
      margin-right: 30px;
    }
  }
}

@media(max-width: 767px) {
  .clients {
    margin-top: 140px;
  }

  .clients_title {
    font-size: 38px;
    line-height: 110%;
  }

  .clients_subtitle {
    font-size: 17px;
    line-height: 150%;
    margin-top: 30px;
    padding: 0;
  }

  .clients {
    overflow: hidden;
  }

  .clients_logos {
    margin-top: 50px;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .client_logo {
    flex: 0 1 calc(50% - 20px);
    height: auto;
    margin-left: 0;
    margin-right: 20px;
    margin-bottom: 20px;

    &:not(:nth-of-type(4n+4)) {
      margin-right: 0;
    }

    &:not(:nth-of-type(3n+3)) {
      margin-right: 0;
    }

    &:not(:nth-of-type(4n+4)) {
      margin-right: 0;
    }

    &:nth-of-type(odd) {
      margin-right: 30px;
    }
  }
}
