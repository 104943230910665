.bread-crumbs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-top: 93px;
  margin-bottom: 40px;
  box-sizing: border-box;

  &--in-article {
    padding-top: 0;
  }
}

.bread-crumbs_link {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #121111;
  text-decoration: none;
  display: flex;

  &--active {
    color:#B9B9B9;

    &:hover {
      color: #6ADF91;
    }
  }

  &:not(:last-child)::after {
    content: "/";
    display: inline-block;
    margin: 0 3px;
  }
}

@media(max-width: 1190px) {
  .bread-crumbs {
    padding-top: 90px;

    &--in-article {
      padding-top: 0;
    }
  }
}

@media(max-width: 767px) {
  .bread-crumbs {
    padding-top: 50px;

    &--in-article {
      padding-top: 0;
    }
  }
}
