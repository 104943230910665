.footer {
  background: #010E30;
  padding-top: 80px;
  padding-bottom: 40px;

  & .center {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.footer_bottom {
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 95px;
}

.footer_column {
  flex-basis: 367px
}

.footer_privacy-policy.footer_column--one {
  flex-basis: auto;
}

.footer_text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 160%;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 10px;
}

.footer_link {
  text-decoration-line: underline;
  color: white;

  &:hover {
    color:  #6ADF91;
    cursor: pointer;
  }
}


.footer_privacy-policy,
.footer_privacy-policy-link {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 160%;
  color: #B3B7C1;
  margin: 0;
}

.footer_privacy-policy-link:hover {
  color:  #6ADF91;
  cursor: pointer;
}

.footer_privacy-policy-link {
  flex-basis: 367px;
}

@media(max-width: 1190px) {
  .footer_column--one {
    flex-basis: 300px;
  }

  .footer_column--two {
    flex-basis: 280px;
  }

  .footer_text {
    font-size: 18px;
    line-height: 160%;
  }

  .footer_bottom {
    margin-top: 70px;
  }

  .footer_privacy-policy {
    max-width: 330px;
  }

  .footer_privacy-policy-link {
    max-width: 300px;
  }
}

@media(max-width: 767px) {
  .footer_text {
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 8px;

    &--top {
      margin-bottom: 25px;
    }
  }

  .footer .center div.footer_bottom {
    margin-top: 50px;
  }

  .footer_privacy-policy-link {
    order: 1;
    margin-bottom: 20px;
  }

  .footer_privacy-policy {
    order: 2
  }
}
