.cases {
  padding-top: 180px;

  & .center {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;
    padding-left: 70px;
    box-sizing: border-box;
  }
}

.cases_title {
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  color: #121111;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 30px;
}

.cases_button {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  padding: 22px 38px;
  margin-right: 30px;
}

.cases_carousel {
  margin-top: 50px;
  max-width: 100%;
}

.cases-carousel_item {
  position: relative;
}

.cases-carousel_img {
  background: #F3F3F3;
  border-radius: 12px;
  padding: 20px;

  & img {
    width: 100%;
    height: auto;
  }
}

.cases-carusel_controller {
  position: absolute;
  z-index: 1;
  width: 80px;
  height: 80px;
  background: #6ADF91;
  box-shadow: 0px 10px 34px rgba(106, 223, 145, 0.5);
  border-radius: 50%;
  border: none;
  top: 239px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &--next {
    right: -71px;
  }

  &--prev {
    left: -71px;
  }
}

.cases-carusel_description {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 30px;
}

.cases-carusel_title {
  margin-top: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  line-height: 110%;
  color: #121111;
  width: 100%;
  margin-bottom: 20px;
}

.cases-carusel_index {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  color: #B3B7C1;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 78px;

  & span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 140%;
    color: #121111;
    margin-top: 6px;
  }
}

@media(max-width: 1190px) {
  .cases .center {
    padding-right: 0;
    padding-left: 0;
  }

  .cases_title {
    font-size: 54px;
    line-height: 110%;
    padding-left: 0;
  }

  .cases_button {
    margin-right: 0;
  }

  .cases-carusel_controller {
    width: 60px;
    height: 60px;
    top: 157px;

    &--next {
      right: 0;
    }

    &--prev {
      left: 0;
    }

    & svg {
      width: 8.26px;
      height: 13.52px;
    }
  }

  .cases-carousel_img {
    padding: 14px;
  }

  .cases-carusel_description {
    padding-left: 0;
  }
}

@media(max-width: 767px) {
  .cases {
    padding-top: 100px;
    margin-top: 40px;

    & .center {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .cases_title {
    font-size: 38px;
    line-height: 110%;
    order: 1;
  }

  .cases_carousel {
    order: 2
  }

  .cases_button {
    order: 3;
    margin-top: 40px;
    width: 100%;
  }

  .cases-carusel_controller {
    width: 45px;
    height: 45px;
    top: -65px;

    &--prev {
      right: 55px;
      left: auto;
    }
  }

  .cases-carousel_img {
    padding: 6px;
  }

  .cases_carousel {
    margin-top: 95px;
  }

  .cases-carusel_description {
    margin-top: 20px;
  }

  .cases-carusel_title {
    font-size: 21px;
    line-height: 110%;
    margin-bottom: 15px;
  }

  .cases-carusel_index {
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 15px;
    margin-right: 46px;

    & span {
      font-size: 18px;
      line-height: 140%;
    }
  }
}
