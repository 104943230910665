.lead {
  overflow: hidden;
}

.lead .center {
  display: flex;
  flex-direction: column;
  position: relative;
}

.lead {
  &--left .center {
    align-items: flex-start;
  }

  &--right .center {
    align-items: flex-end;
  }

  &--one .center,
  &--two .center {
    padding-top: 188px;
  }

  &--three .center {
    padding-top: 285px;
  }

  &--four .center {
    padding-top: 263px;
  }
}

.lead_description {
  flex-shrink: 0;
}

.lead {
  &--one .lead_description {
    margin-bottom: 133px;
  }

  &--two .lead_description {
    margin-bottom: 99px;
  }

  &--three .lead_description {
    margin-bottom: 195px;
  }

  &--four .lead_description {
    margin-bottom: 197px;
  }
}

.lead_title {
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  line-height: 110%;
  color: #121111;
  margin-top: 0;
  margin-bottom: 46px;
  display: block;
  max-width: 570px;
}

.lead_text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 160%;
  color: #121111;
  margin: 0;
  display: block;
  max-width: 470px;
}

.lead {
  &--left .lead_top {
    display: flex;
    flex-direction: row;
  }

  &--right .lead_top {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
  }
}

.lead_img {
  position: relative;
}

.lead {
  &--one .lead_img {
    top: -55px;
    right: 0;
  }

  &--two .lead_img {
    top: 0;
    left: 0;
  }

  &--three .lead_img {
    top: -99px;
    right: 0;
  }

  &--four .lead_img {
    top: -101px;
    left: 0;
  }
}


.lead_tabs {
  background: #F3F3F3;
  border-radius: 12px;
  padding: 45px 45px 10px 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.lead_tabs-column {
  flex-basis: 510px;
}

.tab {
  margin-bottom: 35px;
}

.tab_button {
  display: none;
}

.tab_title {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 120%;
  color: #121111;
  margin-top: 0;
  margin-bottom: 10px;
}

.tab_description p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #121111;
  margin: 0;
}

@media(max-width: 1190px) {
  .lead_description {
    max-width: 420px;
  }

  .lead_title {
    font-size: 54px;
    line-height: 110%;
    margin-bottom: 50px;
    max-width: 470px;
  }

  .lead_text {
    font-size: 18px;
    line-height: 160%;
    max-width: 420px;
  }

  .lead_img {
    width: 540px;
    height: 540px;
  }

  .lead {
    &--one .lead_img {
      top: -50px;
    }

    &--two .lead_img {
      top: -20px;
    }

    &--three .lead_img {
      top: -101px;
    }

    &--four .lead_img {
      top: -107px;
    }
  }

  .lead_tabs {
    padding: 45px 45px 25px;
  }

  .lead_tabs-column {
    flex-basis: 100%;
  }

  .tab {
    position: relative;
    margin-bottom: 20px;

    &.open .tab_description {
      transform: scaleY(1);
      transform-origin: top;
      transition: transform 0.4s ease;
      height: auto;
    }

    &.open .tab_button {
      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    &.open .tab_title {
      border-bottom-color: transparent;
      padding-bottom: 10px;
      margin-bottom: 0;
    }

    &.open .tab_description {
      border-bottom: 1px solid #B3B7C1;
      padding-bottom: 20px;
    }
  }

  .tab_description {
    height: 0;
    transition: height 1s ease-out;
    transform: scaleY(0);
  }

  .tab_title {
    border-bottom: 1px solid #B3B7C1;
    padding-bottom: 20px;
    padding-right: 51px;
    margin-bottom: 0;
  }

  .tab_button {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    right: 0;

    &:hover {
      cursor: pointer;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 2px;
      height: 24px;
      background: #67D690;
      position: absolute;
      top: 0;
      right: 11px;
      transition: all 0.4s ease-in-out;
    }

    &::before {
      transform: rotate(90deg);
    }

    &::after {
      transform: rotate(-180deg);
    }
  }

  .lead {
    &--one .lead_description {
      margin-bottom: 142px;
    }

    &--two .lead_description {
      margin-bottom: 122px;
    }

    &--three .lead_description {
      margin-bottom: 184px;
    }

    &--four .lead_description {
      margin-bottom: 201px;
    }
  }

  .lead {
    &--one .center {
      padding-top: 188px;
    }

    &--two .center {
      padding-top: 208px;
    }

    &--three .center {
      padding-top: 285px;
    }

    &--four .center {
      padding-top: 294px;
    }
  }
}

@media(max-width: 767px) {
  .lead .center {
    padding-top: 100px;
  }

  .lead--one,
  .lead--three {
    margin-bottom: 40px;
  }

  .lead {
    &--one .lead_description {
      margin-bottom: 30px;
    }

    &--two .lead_description {
      margin-bottom: 30px;
    }

    &--three .lead_description {
      margin-bottom: 30px;
    }

    &--four .lead_description {
      margin-bottom: 30px;
    }
  }

  .lead {
    &--left .center {
      align-items: flex-start;
    }

    &--right .center {
      align-items: flex-start;
    }
  }

  .lead {
    &--left .lead_top {
      flex-direction: column;
    }

    &--right .lead_top {
      flex-direction: column;
    }
  }

  .lead_top {
    width: 100%;
  }

  .lead picture {
    width: calc(100% + 30px);
    margin-right: -15px;
    margin-left: -15px;
    box-sizing: border-box;
    margin-bottom: 30px;
  }

  .lead {
    &--one .lead_img {
      top: 0;
    }

    &--two .lead_img {
      top: 0;
    }

    &--three .lead_img {
      top: 0;
    }

    &--four .lead_img {
      top: 0;
    }
  }

  .lead_img {
    width: 100%;
    height: auto;
  }

  .lead_description {
    max-width: none;
    width: 100%;
  }

  .lead_title {
    font-size: 38px;
    line-height: 110%;
    margin-bottom: 30px;
  }

  .lead_text {
    font-size: 17px;
    line-height: 150%;
  }

  .lead_tabs {
    padding: 30px 15px 12px 15px;
  }

  .tab {
    margin-bottom: 18px;
  }

  .tab_title {
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 0;
  }

  .tab_description {
    font-size: 15px;
    line-height: 150%;
  }
}
